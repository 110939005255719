import request from "@/utils/request";

// BatchInventory
export function batchInventoryList(params) {
  return request({ url: `/batch_inventories/`, method: "get", params });
}

export function batchInventoryExport(params) {
  return request({
    url: "/batch_inventories/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// WarehouseInventory
export function warehouseInventoryList(params) {
  return request({ url: `/warehouse_inventories/`, method: "get", params });
}

export function warehouseInventoryExport(params) {
  return request({
    url: "/warehouse_inventories/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

export function warehouseInventoryTotalAmount(params) {
  return request({ url: `/warehouse_inventories/total_amount/`, method: "get", params });
}

// StockInReport
export function stockInReportList(params) {
  return request({ url: `/stock_in_reports/`, method: "get", params });
}

export function stockInReportExport(params) {
  return request({
    url: "/stock_in_reports/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// StockOutReport
export function stockOutReportList(params) {
  return request({ url: `/stock_out_reports/`, method: "get", params });
}

export function stockOutReportExport(params) {
  return request({
    url: "/stock_out_reports/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}
